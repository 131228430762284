import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import { Unit } from 'state/units/types'
import type { SummaryHistoryItem, SummaryState } from './types'
import { getSummary } from './helpers'

const noSummaryConfig = {
  isInitialized: false,
  isLoading: true,
  hasError: false,
  summaryHistory: [],
  activeUnits: [],
}

interface SetSummaryPayload {
  summaryHistory: SummaryHistoryItem[]
  activeUnits: Unit[]
}

const initialState: SummaryState = { ...noSummaryConfig }

export const summarySlice = createSlice({
  name: 'Summary',
  initialState,
  reducers: {
    fetchSummary: (state) => {
      state.isLoading = true
      state.hasError = false
    },
    setSummary: (state, action: PayloadAction<SetSummaryPayload>) => {
      state.summaryHistory = action.payload.summaryHistory
      state.activeUnits = action.payload.activeUnits
        .sort((a, b) => b.unitId.localeCompare(a.unitId))
        .sort((a, b) => new Date(b.notified).valueOf() - new Date(a.notified).valueOf())
        .sort((a, b) => {
          if (a.matchType.includes('similar')) {
            return 1
          }
          if (b.matchType.includes('similar')) {
            return -1
          }
          return 0
        })
      state.isLoading = false
      state.isInitialized = true
      state.hasError = false
    },
    fetchSummaryFailed: (state) => {
      state.summaryHistory = []
      state.activeUnits = []
      state.isInitialized = false
      state.isLoading = false
      state.hasError = true
    },
  },
})

export const doFetchSummary = (buildingId: string) => async (dispatch) => {
  try {
    dispatch(fetchSummary())
    const { summaryHistory, activeUnits } = await getSummary(buildingId)
    dispatch(
      setSummary({
        summaryHistory,
        activeUnits,
      }),
    )
  } catch (error) {
    message.error('Error getting summary')
    dispatch(fetchSummaryFailed())
  }
}

// Actions
export const { fetchSummary, setSummary, fetchSummaryFailed } = summarySlice.actions

export default summarySlice.reducer
