import axios from 'axios'
import { API_ADDRESS } from 'config'
import { Building } from './types'

export const getBuilding = async (buildingId: string): Promise<Building> => {
  const userData = localStorage.getItem('bba-token') ? JSON.parse(localStorage.getItem('bba-token')) : null
  const url = `${API_ADDRESS}/building?buildingId=${buildingId}`
  const { data } = await axios.get(url)
  return data
}
