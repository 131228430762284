import React from 'react'
import styled from 'styled-components'

const StyledFooterContainer = styled.footer`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`

const StyledFooterText = styled.p`
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
`

const GlobalFooter: React.FC = () => {
  return (
    <StyledFooterContainer>
      <StyledFooterText>© 2015 - {new Date().getFullYear()} BNBGuard.</StyledFooterText>
    </StyledFooterContainer>
  )
}

export default GlobalFooter
