import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import buildingReducer from './building'
import buildingListingsReducer from './building-listings'
import unitsReducer from './units'
import unitReducer from './unit'
import nearbyListingsReducer from './nearby-listings'
import summaryReducer from './summary'
import accountReducer from './account'
import usersReducer from './users'
import loginProgressReducer from './login-progress'
import usersAccessListReducer from './users-access'

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    building: buildingReducer,
    buildingListings: buildingListingsReducer,
    units: unitsReducer,
    unit: unitReducer,
    nearbyListings: nearbyListingsReducer,
    summary: summaryReducer,
    account: accountReducer,
    users: usersReducer,
    loginProgress: loginProgressReducer,
    accessList: usersAccessListReducer,
  },
})

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch()

export default store
