import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import type { NearbyListing, NearbyListingsState } from './types'
import { getNearbyListings } from './helpers'

const noNearbyListingsConfig = {
  isInitialized: false,
  isLoading: true,
  hasError: false,
  nearbyListings: [],
}

interface SetNearbyListingsPayload {
  nearbyListings: NearbyListing[]
}

const initialState: NearbyListingsState = { ...noNearbyListingsConfig }

export const nearbyListingsSlice = createSlice({
  name: 'NearbyListings',
  initialState,
  reducers: {
    fetchNearbyListings: (state) => {
      state.isLoading = true
      state.hasError = false
    },
    setNearbyListings: (state, action: PayloadAction<SetNearbyListingsPayload>) => {
      state.nearbyListings = action.payload.nearbyListings
      state.isLoading = false
      state.isInitialized = true
      state.hasError = false
    },
    fetchNearbyListingsFailed: (state) => {
      state.nearbyListings = []
      state.isInitialized = false
      state.isLoading = false
      state.hasError = true
    },
  },
})

export const doFetchNearbyListings = (buildingId: string) => async (dispatch) => {
  try {
    dispatch(fetchNearbyListings())
    const nearbyListings = await getNearbyListings(buildingId)
    dispatch(
      setNearbyListings({
        nearbyListings,
      }),
    )
  } catch (error) {
    message.error('Error getting nearby listings')
    dispatch(fetchNearbyListingsFailed())
  }
}

// Actions
export const { fetchNearbyListings, setNearbyListings, fetchNearbyListingsFailed } = nearbyListingsSlice.actions

export default nearbyListingsSlice.reducer
