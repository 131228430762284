import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import type { UnitState, Unit, UnitListing, UnitSummary } from './types'
import { getUnit } from './helpers'

const noUnitConfig = {
  isInitialized: false,
  isLoading: true,
  hasError: false,
  unit: null,
  listings: [],
  checkHistory: [],
}

interface SetUnitPayload {
  unit: Unit
  listings: UnitListing[]
  checkHistory: UnitSummary[]
}

const initialState: UnitState = { ...noUnitConfig }

export const unitSlice = createSlice({
  name: 'Unit',
  initialState,
  reducers: {
    fetchUnit: (state) => {
      state.isLoading = true
      state.hasError = false
    },
    setUnit: (state, action: PayloadAction<SetUnitPayload>) => {
      state.unit = action.payload.unit
      state.listings = action.payload.listings
      state.checkHistory = action.payload.checkHistory
      state.isLoading = false
      state.isInitialized = true
      state.hasError = false
    },
    fetchUnitFailed: (state) => {
      state.unit = null
      state.listings = null
      state.checkHistory = null
      state.isInitialized = false
      state.isLoading = false
      state.hasError = true
    },
  },
})

export const doFetchUnit = (buildingId: string, unitId: string) => async (dispatch) => {
  try {
    dispatch(fetchUnit())
    const { unit, listings, checkHistory } = await getUnit(buildingId, unitId)
    dispatch(
      setUnit({
        unit,
        listings,
        checkHistory,
      }),
    )
  } catch (error) {
    message.error('Error getting unit')
    dispatch(fetchUnitFailed())
  }
}

// Actions
export const { fetchUnit, setUnit, fetchUnitFailed } = unitSlice.actions

export default unitSlice.reducer
