import axios from 'axios'
import { API_ADDRESS } from 'config'
import { NearbyListing } from './types'

export const getNearbyListings = async (buildingId: string): Promise<NearbyListing[]> => {
  const url = `${API_ADDRESS}/building/near?buildingId=${buildingId}`
  const { data } = await axios.get(url)

  return data
}
