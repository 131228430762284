import axios from 'axios'
import { LOGINAPI_ADDRESS } from 'config'

interface UsersAccessListState {
  accessList: []
  isInitalized: false
  isLoading: true
  hasError: false
}

export const usersAccessFullList = async (): Promise<UsersAccessListState> => {
  const tokenData = localStorage.getItem('bba-token') ? JSON.parse(localStorage.getItem('bba-token')) : null
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenData.token}`,
    },
  }
  const url = `${LOGINAPI_ADDRESS}/api/users/buildings-access`
  const { data } = await axios.get(url, config)
  console.log(data)
  return data
}
