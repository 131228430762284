import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import { UsersAccessListState } from './types'
import { usersAccessFullList } from './helpers'

const noUsersAccessListConfig = {
  accessList: [],
  isInitalized: false,
  isLoading: true,
  hasError: false,
}

interface UsersAccessListPayload {
  accessList?: []
}

const initialState: UsersAccessListState = { ...noUsersAccessListConfig }

export const usersAccessListSlice = createSlice({
  name: 'AccessList',
  initialState,
  reducers: {
    fetchAccessList: (state) => {
      state.isLoading = true
      state.hasError = false
    },
    setAccessList: (state, action: PayloadAction<UsersAccessListPayload>) => {
      state.accessList = action.payload.accessList
      state.isLoading = false
      state.isInitalized = true
      state.hasError = false
    },
    deleteAccessList: (state, action: PayloadAction<UsersAccessListPayload>) => {
      state.accessList = []
      state.isInitalized = false
      state.isLoading = false
      state.hasError = false
    },
    fetchAccessListFailed: (state) => {
      state.accessList = []
      state.isInitalized = false
      state.isLoading = false
      state.hasError = true
    },
  },
})

export const fetchUsersAccessList = () => async (dispatch) => {
  try {
    dispatch(fetchAccessList())
    const accessList = await usersAccessFullList()
    dispatch(
      setAccessList({
        accessList,
      }),
    )
  } catch (error) {
    // message.error('Access list not fond')
    // dispatch(fetchAccessListFailed())
  }
}

// Actions
export const { fetchAccessList, setAccessList, fetchAccessListFailed } = usersAccessListSlice.actions

export default usersAccessListSlice.reducer
