import axios from 'axios'
import { API_ADDRESS } from 'config'
import { Unit } from './types'

export const getUnits = async (buildingId: string): Promise<Unit[]> => {
  const url = `${API_ADDRESS}/building/units?buildingId=${buildingId}`
  const { data } = await axios.get(url)

  return data
}
