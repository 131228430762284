import axios from 'axios'
import { API_ADDRESS } from 'config'
import { Unit } from 'state/units/types'
import { SummaryHistoryItem } from './types'

interface GetSummaryResponse {
  summaryHistory: SummaryHistoryItem[]
  activeUnits: Unit[]
}

export const getSummary = async (buildingId: string): Promise<GetSummaryResponse> => {
  const url = `${API_ADDRESS}/building/summary?buildingId=${buildingId}`
  const { data } = await axios.get(url)

  return data
}
