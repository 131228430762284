import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import type { Building, BuildingState } from './types'
import { getBuilding } from './helpers'

const noBuildingConfig = {
  isInitialized: false,
  isLoading: true,
  hasError: false,
  building: null,
}

interface SetBuildingPayload {
  building: Building
}

const initialState: BuildingState = { ...noBuildingConfig }

export const buildingSlice = createSlice({
  name: 'Building',
  initialState,
  reducers: {
    fetchBuilding: (state) => {
      state.isLoading = true
      state.hasError = false
    },
    setBuilding: (state, action: PayloadAction<SetBuildingPayload>) => {
      state.building = action.payload.building
      state.isLoading = false
      state.isInitialized = true
      state.hasError = false
    },
    fetchBuildingFailed: (state) => {
      state.building = null
      state.isInitialized = false
      state.isLoading = false
      state.hasError = true
    },
  },
})

export const doFetchBuilding = (buildingId: string) => async (dispatch) => {
  console.log('here')
  console.log(buildingId)
  try {
    dispatch(fetchBuilding())
    const building = await getBuilding(buildingId)
    dispatch(
      setBuilding({
        building,
      }),
    )
  } catch (error) {
    message.error('Error getting building')
    dispatch(fetchBuildingFailed())
  }
}

// Actions
export const { fetchBuilding, setBuilding, fetchBuildingFailed } = buildingSlice.actions

export default buildingSlice.reducer
