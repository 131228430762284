import React from 'react'
import { Menu, Menu as AntMenu } from 'antd'
import { NavLink, useLocation } from 'react-router-dom'
import { useBuilding } from 'state/building/hooks'
import { IconModule } from '../Icons'
import links from './config'

interface IconProps {
  size: string
}

const Icons = IconModule as unknown as { [key: string]: React.FC<IconProps> }

const MenuItems: React.FC = () => {
  const { building, isInitialized } = useBuilding()
  const location = useLocation()

  return (
    <AntMenu
      mode="inline"
      selectedKeys={
        isInitialized &&
        links
          .filter((link) => `/${building.internalId}${link.path}` === location.pathname)
          .map((link) => link.id.toString())
      }
      style={{ height: '100%', borderRight: 0 }}
      theme="dark"
    >
      {isInitialized &&
        links.map((entry) => {
          const Icon = Icons[entry.icon]
          return (
            <Menu.Item
              key={entry.id}
              icon={<Icon size={entry.iconSize} />}
              title={entry.name}
              style={{ paddingLeft: 16, paddingRight: 16, fontSize: 15, display: 'inline-flex', alignItems: 'center' }}
            >
              <NavLink exact to={`/${building.internalId}${entry.path}`} style={{ marginLeft: 4 }}>
                {entry.name}
              </NavLink>
            </Menu.Item>
          )
        })}
    </AntMenu>
  )
}

export default MenuItems
