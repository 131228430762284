import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import { UsersState } from './types'
import { getUsersData, usersLogin, userUpdate, usersTwoFactor, usersVerifyTwofactorCode } from './helpers'
import { setLogin } from '../login-progress'
import { setBuilding, doFetchBuilding } from '../building'

const noUsersConfig = {
  shortName: null,
  userId: null,
  isAdmin: null,
  role: null,
  username: null,
  phonenumber: null,
  pic: null,
  _id: null,
  selectedUsers: null,
  councilsAcList: [],
  buildingsAcList: [],
  isAuthenticated: false,
  isLoading: true,
  hasError: false,
}

interface SetUsersPayload {
  shortName: string
  userId: string
  isAdmin: string
  role: string
  pic: string
  username: string
  phonenumber: string
  _id?: string
  councilsAcList: []
  buildingsAcList: []
}

interface AddUserPayload {
  shortName: string
  userId: string
  isAdmin: string
  role: string
  pic: string
  username: string
  phonenumber: string
}

const initialState: UsersState = { ...noUsersConfig }

export const usersSlice = createSlice({
  name: 'Users',
  initialState,
  reducers: {
    fetchUsers: (state) => {
      state.isLoading = true
      state.hasError = false
    },
    setUsers: (state, action: PayloadAction<SetUsersPayload>) => {
      state.shortName = action.payload.shortName
      state.userId = action.payload.userId
      state.isAdmin = action.payload.isAdmin
      state.role = action.payload.role
      state.pic = action.payload.pic
      state.username = action.payload.username
      state.phonenumber = action.payload.phonenumber
      state.selectedUsers = action.payload.shortName
      state.councilsAcList = action.payload.councilsAcList
      state.buildingsAcList = action.payload.buildingsAcList
      state.isLoading = false
      state.isAuthenticated = false
      state.hasError = false
    },
    updateUsers: (state, action: PayloadAction<SetUsersPayload>) => {
      state.shortName = action.payload.shortName
      state.userId = action.payload.userId
      state.isAdmin = action.payload.isAdmin
      state.role = action.payload.role
      state.pic = action.payload.pic
      state.username = action.payload.username
      state.phonenumber = action.payload.phonenumber
      state.selectedUsers = action.payload.shortName
      state.councilsAcList = action.payload.councilsAcList
      state.buildingsAcList = action.payload.buildingsAcList
      state.isLoading = false
      state.isAuthenticated = true
      state.hasError = false
    },
    addUsers: (state, action: PayloadAction<AddUserPayload>) => {
      state.shortName = action.payload.shortName
      state.userId = action.payload.userId
      state.isAdmin = action.payload.isAdmin
      state.role = action.payload.role
      state.pic = action.payload.pic
      state.username = action.payload.username
      state.phonenumber = action.payload.phonenumber
      state.isLoading = false
      state.hasError = false
    },
    logoutUsers: (state) => {
      state.shortName = null
      state.userId = null
      state.isAdmin = null
      state.role = null
      state.username = null
      state.phonenumber = null
      state.pic = null
      state._id = null
      state.selectedUsers = null
      state.councilsAcList = []
      state.buildingsAcList = []
      state.isAuthenticated = false
      state.isLoading = false
      state.hasError = false
    },
    fetchUsersFailed: (state) => {
      state.shortName = null
      state.userId = null
      state.isAdmin = null
      state.role = null
      state.username = null
      state.phonenumber = null
      state.pic = null
      state._id = null
      state.selectedUsers = null
      state.councilsAcList = []
      state.buildingsAcList = []
      state.isAuthenticated = false
      state.isLoading = false
      state.hasError = true
    },
  },
})

export const fetchUsersData = (accessToken: string) => async (dispatch) => {
  try {
    dispatch(fetchUsers())
    // console.log(dispatch(fetchUsers()))
    const { shortName, userId, isAdmin, role, username, phonenumber, pic, _id, councilsAcList, buildingsAcList } =
      await getUsersData(accessToken)

    console.log(buildingsAcList)

    dispatch(
      setUsers({
        shortName,
        userId,
        isAdmin,
        role,
        username,
        phonenumber,
        pic,
        _id,
        councilsAcList,
        buildingsAcList,
      }),
    )
  } catch (error) {
    localStorage.clear()
    sessionStorage.clear()
    message.error('User details not found')
    dispatch(fetchUsersFailed())
  }
}

export const authUsers = (email: string, password: string, verUser: boolean) => async (dispatch) => {
  try {
    dispatch(fetchUsers())
    const accessToken = await usersLogin(email, password)
    localStorage.setItem('bba-token', JSON.stringify(accessToken))
    const { _id, shortName, isAdmin, role, userId, username, phonenumber, pic, councilsAcList, buildingsAcList } =
      accessToken

    console.log(buildingsAcList)
    if (verUser !== true) {
      const authTwoFact = await usersTwoFactor(email)
      // set login progress to twofactor
      const loginProgress = 'twoFactor'
      const verifyUser = false
      const token = null
      dispatch(
        setLogin({
          loginProgress,
          verifyUser,
          username,
          token,
        }),
      )
      // set login state but not authenticated yet
      dispatch(
        setUsers({
          shortName,
          userId,
          isAdmin,
          role,
          pic,
          username,
          phonenumber,
          _id,
          councilsAcList,
          buildingsAcList,
        }),
      )
    } else {
      dispatch(
        updateUsers({
          shortName,
          userId,
          isAdmin,
          role,
          pic,
          username,
          phonenumber,
          _id,
          councilsAcList,
          buildingsAcList,
        }),
      )
    }
  } catch (error) {
    localStorage.clear()
    sessionStorage.clear()
    message.error('User not found...5')
    dispatch(fetchUsersFailed())
  }
}

export const verifyTwoFactorCode = (code: string, username: string) => async (dispatch) => {
  try {
    dispatch(fetchUsers())
    const userVerification = await usersVerifyTwofactorCode(code, username)

    console.log(userVerification)

    const { status, token } = userVerification
    console.log(status)
    console.log(token)
    const accessToken = localStorage.getItem('bba-token')
    const accessData = JSON.parse(accessToken)

    const shortName = accessData.shortName
    const userId = accessData.userId
    const isAdmin = accessData.isAdmin
    const role = accessData.role
    const pic = accessData.pic
    const phonenumber = accessData.phonenumber
    const _id = accessData._id
    const councilsAcList = accessData.councilsAcList
    const buildingsAcList = accessData.buildingsAcList

    // let isMultiple = false

    // if (buildingsAcList.length > 1) {
    //   isMultiple = true
    // }

    const loginProgress = 'loggedIn'
    const verifyUser = true

    // doFetchBuilding

    if (status === 'ok') {
      dispatch(
        updateUsers({
          shortName,
          userId,
          isAdmin,
          role,
          pic,
          username,
          phonenumber,
          _id,
          councilsAcList,
          buildingsAcList,
        }),
      )
      dispatch(
        setLogin({
          loginProgress,
          verifyUser,
          username,
          token,
        }),
      )
      dispatch(doFetchBuilding('ParkSt27'))
    }
    message.success('Your code was successful')
  } catch (error) {
    // localStorage.clear()
    // sessionStorage.clear()
    console.log(error)
    message.error('User not found...6')
    // dispatch(fetchUsersFailed())
  }
}

export const authUserstwofactor = (email: string) => async (dispatch) => {
  try {
    dispatch(fetchUsers())
    const authTwoFact = await usersTwoFactor(email)

    const { loginProgress, status, token, verifyUser } = authTwoFact

    if (status === 'ok') {
      //   localStorage.setItem('bba-token', JSON.stringify(accessToken))
      //   const { _id, shortName, isAdmin, role, userId, username, phonenumber, pic } = accessToken
      //   dispatch(
      //     setUsers({
      //       shortName,
      //       userId,
      //       isAdmin,
      //       role,
      //       pic,
      //       username,
      //       phonenumber,
      //       _id,
      //     }),
      //   )
    }
    message.success('Your code was correct.')
  } catch (error) {
    localStorage.clear()
    sessionStorage.clear()
    message.error('User not found...8')
    dispatch(fetchUsersFailed())
  }
}

export const updateProfile = (user) => async (dispatch) => {
  try {
    const updatedUser = await userUpdate(user)
    localStorage.setItem('bba-token', JSON.stringify(updatedUser))
    const { _id, shortName, isAdmin, role, userId, username, phonenumber, pic, councilsAcList, buildingsAcList } =
      updatedUser
    dispatch(
      setUsers({
        shortName,
        userId,
        isAdmin,
        role,
        pic,
        username,
        phonenumber,
        _id,
        councilsAcList,
        buildingsAcList,
      }),
    )
    message.success('User updated')
  } catch (error) {
    // localStorage.clear()
    // sessionStorage.clear()
    message.error('User update failed')
    dispatch(fetchUsersFailed())
  }
}

// Actions
export const { fetchUsers, setUsers, addUsers, logoutUsers, updateUsers, fetchUsersFailed } = usersSlice.actions

export default usersSlice.reducer
