import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Layout } from 'antd'
import Dashboard from './views'
import Login from './views/Login/Login'

import 'antd/dist/antd.css'
import 'styles/antd-overrides.css'

const App: React.FC = () => {
  return (
    <Layout>
      <Switch>
        <Route path="/:buildingId">
          <Dashboard />
        </Route>
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </Layout>
  )
}

export default App
