interface MenuItem {
  id: number
  path: string
  name: string
  icon: string
  iconSize: string
}

const config: MenuItem[] = [
  {
    id: 1,
    path: '/',
    name: 'Home',
    icon: 'FaHome',
    iconSize: '1.4em',
  },
  {
    id: 2,
    path: '/units',
    name: 'Units',
    icon: 'FaBed',
    iconSize: '1.4em',
  },
  {
    id: 3,
    path: '/listings',
    name: 'Listings',
    icon: 'FaMapMarkerAlt',
    iconSize: '1.4em',
  },
  /*
  {
    id: 4,
    path: '/nearby',
    name: 'Nearby',
    icon: 'FaSearch',
    iconSize: '1.3em',
  },
  */
]

export default config
