import axios from 'axios'
import { API_ADDRESS } from 'config'
import { Unit, UnitListing, UnitSummary } from './types'

interface GetUnitResponse {
  unit: Unit
  listings: UnitListing[]
  checkHistory: UnitSummary[]
}

export const getUnit = async (buildingId: string, unitId: string): Promise<GetUnitResponse> => {
  const url = `${API_ADDRESS}/building/unit?buildingId=${buildingId}&unitId=${unitId}`
  const { data } = await axios.get(url)

  return data
}
