import React from 'react'
import { Layout, Grid } from 'antd'
import MenuItems from './MenuItems'

const { Content, Sider } = Layout
const { useBreakpoint } = Grid

const Menu: React.FC = ({ children }) => {
  const { lg } = useBreakpoint()
  const isMobile = lg === false

  return (
    <Layout>
      <Sider
        breakpoint="lg"
        collapsedWidth="60"
        width="200"
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 64,
          paddingTop: 16,
          paddingBottom: 16,
        }}
      >
        <MenuItems />
      </Sider>
      <Layout style={{ marginLeft: isMobile ? 60 : 200, marginTop: 64 }}>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 'calc(100vh - 88px)',
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

export default Menu
