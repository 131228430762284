import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { useAppDispatch } from 'state'
import LoginForm from 'components/LoginForm/LoginForm'
import ResetForm from 'components/LoginForm/ResetForm'
import PasswordForm from 'components/LoginForm/PasswordForm'
import CodeForm from 'components/LoginForm/CodeForm'
import { useLoginProgress } from 'state/login-progress/hooks'
import { motion } from 'framer-motion'
import { LogoIcon } from 'components/Icons'
import { useUsers } from 'state/users/hooks'
import { fetchUsersFailed } from 'state/users'

const BoxContainer = styled.div`
  width: 280px;
  min-height: 485px;
  display: flex;
  flex-direction: column;
  border-radius: 19px;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(15, 15, 15, 0.28);
  position: relative;
  overflow: hidden;
`

const TopContainer = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 1.8em;
  padding-bottom: 5em;
  z-index: 2;
`

const BackDrop = styled(motion.div)`
  width: 160%;
  height: 550px;
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  transform: rotate(60deg);
  top: -290px;
  left: -70px;
  background: rgb(241, 196, 15);
  background: linear-gradient(58deg, rgba(37, 72, 116, 1) 20%, rgba(37, 72, 116, 1) 100%);
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 5;
`

const HeaderText = styled.h2`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.24;
  color: #fff;
  z-index: 10;
  margin: 0 0 10px 0;
`

const SmallText = styled.h5`
  color: #fff;
  font-weight: 500;
  font-size: 11px;
  z-index: 10;
  margin: 0;
  margin-top: 7px;
`

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1.8em;
`

const StyledPageWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #000;
  justify-content: center;
  width: 100%;
  height: 100vh;
`

const StyledLogoWrapper = styled.div`
  max-width: 70%;
  margin: 0 auto 28px;
`
const backdropVariants = {
  expanded: {
    width: '233%',
    height: '1050px',
    borderRadius: '20%',
    transform: 'rotate(60deg)',
  },
  collapsed: {
    width: '160%',
    height: '550px',
    borderRadius: '50%',
    transform: 'rotate(60deg)',
  },
}

const expandingTransition = {
  type: 'spring',
  duration: 2.3,
  stiffness: 30,
}

// const switchToPasswordReset = () => {}

const Login: React.FC = () => {
  const [isExpanded, setExpanded] = useState(false)
  const [active, setActive] = useState('loginform')

  const { isAuthenticated, username } = useUsers()

  const loginProgress = useLoginProgress()
  const dispatch = useAppDispatch()

  const playExpandingAnimation = () => {
    setExpanded(true)
    setTimeout(() => {
      setExpanded(false)
    }, expandingTransition.duration * 1000 - 1500)
  }

  const switchToReset = () => {
    playExpandingAnimation()
    setTimeout(() => {
      setActive('resetform')
    }, 400)
  }

  const switchToSignin = () => {
    playExpandingAnimation()
    setTimeout(() => {
      setActive('loginform')
      fetchUsersFailed()
      localStorage.clear()
      sessionStorage.clear()
    }, 400)
  }

  const switchToCode = () => {
    playExpandingAnimation()
    setTimeout(() => {
      setActive('codeform')
    }, 400)
  }

  const switchToUpdate = () => {
    playExpandingAnimation()
    setTimeout(() => {
      setActive('passwordform')
    }, 400)
  }

  // Lets check to see where our state is up to and render accordingly
  useEffect(() => {
    if (loginProgress) {
      if (
        loginProgress.loginProgress === 'resetPassword' ||
        loginProgress.loginProgress === 'twoFactor' ||
        (!isAuthenticated && username !== null)
      ) {
        playExpandingAnimation()
        setTimeout(() => {
          setActive('codeform')
        }, 400)
      } else if (loginProgress.loginProgress === 'passwordReset') {
        playExpandingAnimation()
        setTimeout(() => {
          setActive('passwordform')
        }, 400)
      } else if (loginProgress.loginProgress === 'passwordLogin' || loginProgress.loginProgress === null) {
        playExpandingAnimation()
        setTimeout(() => {
          setActive('loginform')
        }, 400)
      } else if (loginProgress.loginProgress === 'codeformAgain') {
        playExpandingAnimation()
        setTimeout(() => {
          setActive('codeform')
        }, 400)
      }
    }
  }, [loginProgress, isAuthenticated, username])

  const contextValue = { switchToReset, switchToSignin, switchToCode, switchToUpdate }

  return (
    <StyledPageWrapper>
      <BoxContainer>
        <TopContainer>
          <BackDrop
            initial={false}
            animate={isExpanded ? 'expanded' : 'collapsed'}
            variants={backdropVariants}
            transition={expandingTransition}
          />

          {active === 'loginform' && (
            <HeaderContainer>
              <HeaderText>Welcome to</HeaderText>
              <LogoIcon isMobile={false} isColoured />
              <SmallText>BUILDINGS DASHBOARD</SmallText>
              <SmallText>Please sign-in to continue!</SmallText>
            </HeaderContainer>
          )}
          {active === 'resetform' && (
            <HeaderContainer>
              <HeaderText>Forgotten password</HeaderText>
              <LogoIcon isMobile={false} isColoured />
              <SmallText>BUILDINGS DASHBOARD</SmallText>
              <SmallText>Please enter your email to continue.</SmallText>
            </HeaderContainer>
          )}
          {active === 'codeform' && (
            <HeaderContainer>
              <HeaderText>Enter code</HeaderText>
              <LogoIcon isMobile={false} isColoured />
              <SmallText>BUILDINGS DASHBOARD</SmallText>
              <SmallText>Please enter your verification code.</SmallText>
            </HeaderContainer>
          )}
          {active === 'passwordform' && (
            <HeaderContainer>
              <HeaderText>New password</HeaderText>
              <LogoIcon isMobile={false} isColoured />
              <SmallText>BUILDINGS DASHBOARD</SmallText>
              <SmallText>Please enter your new password.</SmallText>
            </HeaderContainer>
          )}
        </TopContainer>

        <InnerContainer>
          {active === 'loginform' && <LoginForm />}
          {active === 'resetform' && <ResetForm />}
          {active === 'codeform' && <CodeForm />}
          {active === 'passwordform' && <PasswordForm />}

          {active === 'loginform' && (
            <Button
              type="link"
              style={{ fontSize: '12px', marginTop: '-20px', color: '#254874' }}
              onClick={() => switchToReset()}
            >
              Forgotten Password?
            </Button>
          )}

          {active === 'passwordform' && (
            <div>
              <Button
                type="link"
                style={{ fontSize: '12px', marginTop: '-20px', color: '#254874', textAlign: 'center', width: '100%' }}
                onClick={() => switchToSignin()}
              >
                Back to login
              </Button>
            </div>
          )}
        </InnerContainer>
      </BoxContainer>
    </StyledPageWrapper>
  )
}

export default Login
