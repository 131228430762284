import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import { LoginProgressState } from './types'
import { usersReset, usersVerifyCode, usersResendCode, passwordUpdate } from './helpers'


const noLoginConfig = {
  loginProgress: null,
  verifyUser: false,
  username: null,
  token: null,
  isLoading: true,
  hasError: false,
}

interface UserCodePayload {
  loginProgress: string
  verifyUser: boolean
  username: string
  token: string
}

const initialState: LoginProgressState = { ...noLoginConfig }

export const loginProgressSlice = createSlice({
  name: 'LoginProgress',
  initialState,
  reducers: {
    fetchLogin: (state) => {
      state.isLoading = true
      state.hasError = false
    },
    setLogin: (state, action: PayloadAction<UserCodePayload>) => {
      state.loginProgress = action.payload.loginProgress
      state.verifyUser = false
      state.username = action.payload.username
      state.token = action.payload.token
      state.isLoading = false
      state.hasError = false
    },
    updateLogin: (state, action: PayloadAction<UserCodePayload>) => {
      state.loginProgress = action.payload.loginProgress
      state.verifyUser = true
      state.username = action.payload.username
      state.token = action.payload.token
      state.isLoading = false
      state.hasError = false
    },
    fetchLoginFailed: (state) => {
      state.loginProgress = null
      state.verifyUser = false
      state.username = null
      state.token = null
      state.isLoading = false
      state.hasError = true
    },
  },
})

// resetUsers
export const resetUsers = (email: string) => async (dispatch) => {
  try {
    dispatch(fetchLogin())
    const resetVerification = await usersReset(email)
    const { loginProgress, verifyUser, username, status, token } = resetVerification
    if (status === 'ok') {
      dispatch(
        setLogin({
          loginProgress,
          verifyUser,
          username,
          token,
        }),
      )
    }
    message.success('Verfication code has been sent')
  } catch (error) {
    localStorage.clear()
    sessionStorage.clear()
    message.error('User not found...1')
    dispatch(fetchLoginFailed())
  }
}
// Verify Code
export const verifyCode = (code: string, username: string) => async (dispatch) => {
  try {
    dispatch(fetchLogin())
    const resetVerification = await usersVerifyCode(code, username)
    const { loginProgress, status, token, verifyUser } = resetVerification
    if (status === 'ok') {
      dispatch(
        updateLogin({
          loginProgress,
          verifyUser,
          username,
          token,
        }),
      )
    }
    message.success('Please update your password')
  } catch (error) {
    localStorage.clear()
    sessionStorage.clear()
    message.error('User not found...2')
    dispatch(fetchLoginFailed())
  }
}

// Resend Code
export const resendCode = (username: string) => async (dispatch) => {
  try {
    dispatch(fetchLogin())
    const resetVerification = await usersResendCode(username)

    const { loginProgress, status, token, verifyUser } = resetVerification

    if (status === 'ok') {
      dispatch(
        setLogin({
          loginProgress,
          verifyUser,
          username,
          token,
        }),
      )
    }
    message.success('Your code has been resent.')
  } catch (error) {
    localStorage.clear()
    sessionStorage.clear()
    message.error('User not found...3')
    dispatch(fetchLoginFailed())
  }
}

// Update Passwords
export const updatePasswords = (password: string, token: string, username: string) => async (dispatch) => {
  try {
    dispatch(fetchLogin())

    const changePassword = await passwordUpdate(token, username, password)
    const { loginProgress, status, verifyUser } = changePassword

    if (status === 'ok') {
      dispatch(
        updateLogin({
          loginProgress,
          verifyUser,
          username,
          token,
        }),
      )
    }
    message.success('Your password has been reset. Please login to continue.')
  } catch (error) {
    localStorage.clear()
    sessionStorage.clear()
    message.error('User not found...4')
    dispatch(fetchLoginFailed())
  }
}

// Update progress state
export const updateProgress =
  (loginProgress: string, verifyUser: boolean, username: string, token: string) => async (dispatch) => {
    try {
      dispatch(fetchLogin())
      dispatch(
        updateLogin({
          loginProgress,
          verifyUser,
          username,
          token,
        }),
      )
    } catch (error) {
      localStorage.clear()
      sessionStorage.clear()
      message.error('User not found...')
      dispatch(fetchLoginFailed())
    }
  }

export const { fetchLoginFailed, fetchLogin, setLogin, updateLogin } = loginProgressSlice.actions

export default loginProgressSlice.reducer
