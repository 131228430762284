import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from 'state'
import { Form, Input, Button, Row, Col } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import { useUsers } from 'state/users/hooks'
import { fetchUsersFailed, verifyTwoFactorCode } from 'state/users'
import { verifyCode, resendCode, fetchLoginFailed } from 'state/login-progress'
import { useLoginProgress } from 'state/login-progress/hooks'
import history from 'routerHistory'

const CodeForm = () => {
  const { isAuthenticated, isLoading, hasError } = useUsers()
  const dispatch = useAppDispatch()
  const { username, loginProgress } = useLoginProgress()

  const [formLoading, setFormLoading] = useState(false)

  const onCodeFinish = useCallback(
    (values) => {
      setFormLoading(true)
      const { verification, username, loginProgress } = values
      if (loginProgress === 'resetPassword') {
        dispatch(verifyCode(verification, username))
      } else {
        dispatch(verifyTwoFactorCode(verification, username))
      }
    },
    [dispatch],
  )

  /* Code resend */
  const onCodeResend = useCallback(() => {
    dispatch(resendCode(username))
  }, [dispatch, username])

  /* Back to login clear state */
  const onBacktoLogin = useCallback(() => {
    localStorage.clear()
    sessionStorage.clear()
    dispatch(fetchUsersFailed())
    dispatch(fetchLoginFailed())
  }, [dispatch])

  /* Check state auth if so redirect them */
  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        history.push('/ParkSt27')
      }
    }
  }, [isAuthenticated, isLoading])

  useEffect(() => {
    if (hasError) {
      setFormLoading(false)
    }
  }, [hasError])

  return (
    <Form
      name="login"
      onFinish={onCodeFinish}
      fields={[
        {
          name: ['username'],
          value: username,
        },
        {
          name: ['loginProgress'],
          value: loginProgress,
        },
      ]}
      size="large"
    >
      <Form.Item
        name="verification"
        rules={[
          {
            required: true,
            message: 'Please input your verification code!',
          },
        ]}
      >
        <Input prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Verification Code" />
      </Form.Item>
      <Form.Item hidden name="username">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item hidden name="loginProgress">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%', marginBottom: '0px', background: '#254874' }}
          loading={formLoading}
        >
          Submit Your Code
        </Button>
        <Button
          type="link"
          style={{ fontSize: '12px', marginTop: '0px', color: '#254874', textAlign: 'right', width: '50%' }}
          onClick={() => onCodeResend()}
        >
          Resend Code
        </Button>
        <Button
          type="link"
          style={{ fontSize: '12px', marginTop: '0px', color: '#254874', textAlign: 'left', width: '50%' }}
          onClick={() => onBacktoLogin()}
        >
          Back to Login
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CodeForm
