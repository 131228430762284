import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import type { UnitsState, Unit } from './types'
import { getUnits } from './helpers'

const noUnitsConfig = {
  isInitialized: false,
  isLoading: true,
  hasError: false,
  units: [],
}

interface SetUnitsPayload {
  units: Unit[]
}

const initialState: UnitsState = { ...noUnitsConfig }

export const unitsSlice = createSlice({
  name: 'Units',
  initialState,
  reducers: {
    fetchUnits: (state) => {
      state.isLoading = true
      state.hasError = false
    },
    setUnits: (state, action: PayloadAction<SetUnitsPayload>) => {
      state.units = action.payload.units
        .sort((a, b) => b.unitId.localeCompare(a.unitId))
        .sort((a, b) => new Date(b.notified).valueOf() - new Date(a.notified).valueOf())
        .sort((a, b) => {
          if (a.matchType.includes('similar')) {
            return 1
          }
          if (b.matchType.includes('similar')) {
            return -1
          }
          return 0
        })
      state.isLoading = false
      state.isInitialized = true
      state.hasError = false
    },
    fetchUnitsFailed: (state) => {
      state.units = []
      state.isInitialized = false
      state.isLoading = false
      state.hasError = true
    },
  },
})

export const doFetchUnits = (buildingId: string) => async (dispatch) => {
  try {
    dispatch(fetchUnits())
    const units = await getUnits(buildingId)
    dispatch(
      setUnits({
        units,
      }),
    )
  } catch (error) {
    message.error('Error getting units')
    dispatch(fetchUnitsFailed())
  }
}

// Actions
export const { fetchUnits, setUnits, fetchUnitsFailed } = unitsSlice.actions

export default unitsSlice.reducer
