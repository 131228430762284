import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import type { BuildingListing, BuildingListingsState } from './types'
import { getBuildingListings } from './helpers'

const noBuildingListingsConfig = {
  isInitialized: false,
  isLoading: true,
  hasError: false,
  buildingListings: [],
}

interface SetBuildingListingsPayload {
  buildingListings: BuildingListing[]
}

const initialState: BuildingListingsState = { ...noBuildingListingsConfig }

export const buildingListingsSlice = createSlice({
  name: 'BuildingListings',
  initialState,
  reducers: {
    fetchBuildingListings: (state) => {
      state.isLoading = true
      state.hasError = false
    },
    setBuildingListings: (state, action: PayloadAction<SetBuildingListingsPayload>) => {
      state.buildingListings = action.payload.buildingListings.sort(
        (a, b) => new Date(b.notified).valueOf() - new Date(a.notified).valueOf(),
      )
      state.isLoading = false
      state.isInitialized = true
      state.hasError = false
    },
    fetchBuildingListingsFailed: (state) => {
      state.buildingListings = []
      state.isInitialized = false
      state.isLoading = false
      state.hasError = true
    },
  },
})

export const doFetchBuildingListings = (buildingId: string) => async (dispatch) => {
  try {
    dispatch(fetchBuildingListings())
    const buildingListings = await getBuildingListings(buildingId)
    dispatch(
      setBuildingListings({
        buildingListings,
      }),
    )
  } catch (error) {
    message.error('Error getting building listings')
    dispatch(fetchBuildingListingsFailed())
  }
}

// Actions
export const { fetchBuildingListings, setBuildingListings, fetchBuildingListingsFailed } = buildingListingsSlice.actions

export default buildingListingsSlice.reducer
