import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { doFetchBuilding } from 'state/building'
import { useAppDispatch } from 'state'
import { logoutAccount, updateAccountData } from 'state/account'
import { useAccount, useFetchAccount } from 'state/account/hooks'
import { useUsers } from 'state/users/hooks'
import { fetchUsersAccessList } from 'state/users-access'
import { useAllAccess } from 'state/users-access/hooks'
import { Popover, Select, Menu, Form, Input, AutoComplete } from 'antd'
import history from 'routerHistory'
import { FaSignOutAlt } from 'react-icons/fa'

const StyledText = styled.span`
  margin-left: 10px;
  line-height: 16px;
  text-transform: capitalize;
`

const StyledTrigger = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  > div {
    margin-left: 8px;
  }
`

const StyledButton = styled.button`
  color: rgba(0, 0, 0, 0.65);
  align-items: center;
  display: flex;
  text-transform: capitalize;
  justify-content: flex-start;
  width: 100%;
  background: 0;
  border: 0;
  > svg {
    margin-right: 6px;
  }
  cursor: pointer;
`
const { Search } = Input
const Option = Select.Option

const AccountMenu: React.FC = () => {
  const dispatch = useAppDispatch()
  const [accountName, setAccountName] = useState(null)
  const { ownerName, shortName, isAuthenticated } = useAccount()
  const { buildingsAcList, pic, isAdmin } = useUsers()
  const { accessList, isLoading } = useAllAccess()
  console.log(buildingsAcList)
  const [dataSource, setDataSource] = useState(accessList)
  const AVATAR_LOCATION = '/images/avatar.png'
  useFetchAccount(accountName)

  useEffect(() => {
    dispatch(fetchUsersAccessList())
  }, [dispatch])

  let isMultiple = false
  const items = [...accessList]

  if (buildingsAcList.length > 1) {
    isMultiple = true
  }

  const selectChange = useCallback(
    (value) => {
      const result = accessList.filter((obj) => obj.buildingName === value)
      console.log(result)
      // dispatch(updateAccountData(result[0].buildingId, result[0]._id))
      dispatch(doFetchBuilding(result[0].buildingId))
    },
    [dispatch, accessList],
  )

  const handleChange = useCallback(
    (value) => {
      const result = buildingsAcList.filter((obj) => obj.internalId === value)
      // dispatch(updateAccountData(result[0].buildingName, result[0].internalId))
      dispatch(doFetchBuilding(result[0].internalId))
    },
    [dispatch, buildingsAcList],
  )
  const onTextSearch = useCallback((value) => {
    // const result = accessList.filter((obj) => obj.ownerName === value)
    //   dispatch(updateAccountData(result[0].ownerName, result[0].ownerId))
    // setAccountName(result[0].ownerName)
  }, [])

  /* auto complete */

  const logout = useCallback(() => {
    localStorage.clear()
    sessionStorage.clear()
    dispatch(logoutAccount())
    history.push('/')
  }, [dispatch])

  return (
    <>
      {!isAdmin && isMultiple === true && (
        <Form name="select_building" size="large">
          <Form.Item name="building" style={{ margin: '0 10px 0 0', display: 'inline-block' }}>
            <Select placeholder="Select Building" style={{ width: 220 }} onChange={handleChange}>
              {buildingsAcList.map((option) => (
                <option key={option.internalId} value={option.internalId}>
                  {option.buildingName}
                </option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      )}

      {isAdmin && (
        <Form name="select_building" size="large">
          <Form.Item name="building" style={{ margin: '0 10px 0 0', display: 'inline-block' }}>
            <Select
              showSearch
              placeholder="Select Building"
              optionFilterProp="children"
              style={{ width: 220 }}
              onChange={selectChange}
              onSearch={onTextSearch}
              filterOption={(input, option) =>
                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              {items.map((option) => (
                <option key={option.buildingName} value={option.buildingName}>
                  {option?.buildingName}
                </option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      )}

      <Popover
        placement="bottomRight"
        style={{ padding: 0 }}
        content={
          <StyledButton
            onClick={() => {
              logout()
            }}
          >
            <FaSignOutAlt />
            <span>Signout</span>
          </StyledButton>
        }
      >
        <StyledTrigger>
          <img
            src={AVATAR_LOCATION}
            alt={shortName}
            style={{ width: '40px', marginTop: '-5px', marginRight: '10px', borderRadius: '40px' }}
            className="profilePic"
          />
          <StyledText>Welcome {shortName}</StyledText>
        </StyledTrigger>
      </Popover>
    </>
  )
}
export default AccountMenu
