import React from 'react'
import styled from 'styled-components'
import { Layout, Grid } from 'antd'
import AccountMenu from 'components/AccountMenu/AccountMenu'
import Logo from '../Icons/Logo'

const { Header } = Layout
const { useBreakpoint } = Grid

const StyledLogoWrapper = styled.a`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  width: 180px;
  margin: 0 20px 0 0;
  > img {
    height: 32px;
  }
`

const StyledRightWrapper = styled.a`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`

const GlobalHeader: React.FC = () => {
  const { lg } = useBreakpoint()
  const isMobile = lg === false

  return (
    <Header
      style={{
        position: 'fixed',
        zIndex: 3,
        width: '100%',
        padding: '0 26px 0 16px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <StyledLogoWrapper>
        <Logo isMobile={isMobile} isColoured={false} />
      </StyledLogoWrapper>
      <h1 style={{ color: '#fff', textTransform: 'uppercase', fontSize: '14px', flexGrow: 0.9, padding: '0 0 0 15px' }}>
        Welcome to BNBGuard Buildings Dashboard
      </h1>
      <StyledRightWrapper>
        <AccountMenu />
      </StyledRightWrapper>
    </Header>
  )
}

export default GlobalHeader
