import axios from 'axios'
import { API_ADDRESS } from 'config'
import { BuildingListing } from './types'

export const getBuildingListings = async (buildingId: string): Promise<BuildingListing[]> => {
  const url = `${API_ADDRESS}/building/listings?buildingId=${buildingId}`
  const { data } = await axios.get(url)

  return data
}
