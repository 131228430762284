import React, { lazy, useEffect } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { useAppDispatch } from 'state'
import GlobalHeader from 'components/GlobalHeader/GlobalHeader'
import GlobalFooter from 'components/GlobalFooter/GlobalFooter'
import Menu from 'components/Menu/Menu'
import SuspenseWithChunkError from 'components/SuspenseWithChunkError'
import PageLoader from 'components/Loader/PageLoader'
import { useBuilding } from 'state/building/hooks'
import { doFetchBuilding } from 'state/building'
import { Layout } from 'antd'

import 'antd/dist/antd.css'
import 'styles/antd-overrides.css'

const { Content } = Layout

// Route-based code splitting
const Home = lazy(() => import('./Home/Home'))
const Units = lazy(() => import('./Units/Units'))
const Unit = lazy(() => import('./Unit/Unit'))
const Listings = lazy(() => import('./Listings/Listings'))
const NearbyListings = lazy(() => import('./NearbyListings/NearbyListings'))
const NotFound = lazy(() => import('./NotFound'))

const Dashboard: React.FC = () => {
  const { path } = useRouteMatch()
  const dispatch = useAppDispatch()
  const { buildingId }: any = useParams()
  const { isInitialized, isLoading, hasError } = useBuilding()

  useEffect(() => {
    dispatch(doFetchBuilding(buildingId))
  }, [dispatch, buildingId])

  return (
    <>
      <GlobalHeader />
      <Menu>
        <Content style={{ minHeight: 'calc(100vh - 100px)' }}>
          <SuspenseWithChunkError fallback={<PageLoader />}>
            {isLoading && <PageLoader />}
            {isInitialized && (
              <Switch>
                <Route exact path={path}>
                  <Home />
                </Route>
                <Route exact path={`${path}/units`}>
                  <Units />
                </Route>
                <Route exact path={`${path}/units/:unitId`}>
                  <Unit />
                </Route>
                <Route exact path={`${path}/listings`}>
                  <Listings />
                </Route>
                <Route exact path={`${path}/nearby`}>
                  <NearbyListings />
                </Route>
                {/* 404 */}
                <Route component={NotFound} />
              </Switch>
            )}
            {hasError && <NotFound />}
          </SuspenseWithChunkError>
        </Content>
        <GlobalFooter />
      </Menu>
    </>
  )
}

export default React.memo(Dashboard)
