import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from 'state'
import { Form, Input, Button, Row, Col } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useUsers } from 'state/users/hooks'
import { fetchUsersFailed } from 'state/users'
import { useLoginProgress } from 'state/login-progress/hooks'
import { updatePasswords, fetchLoginFailed } from 'state/login-progress'
import history from 'routerHistory'

const PasswordForm = () => {
  const { isAuthenticated, isLoading, hasError } = useUsers()
  const dispatch = useAppDispatch()

  const [formLoading, setFormLoading] = useState(false)

  const { username, token } = useLoginProgress()

  const onFinishPassword = useCallback(
    (values) => {
      setFormLoading(true)
      const { password, token, username } = values
      dispatch(updatePasswords(password, token, username))
    },
    [dispatch],
  )
  /* Back to login clear state */
  const returnToLogin = useCallback(() => {
    localStorage.clear()
    sessionStorage.clear()
    dispatch(fetchUsersFailed())
    dispatch(fetchLoginFailed())
  }, [dispatch])

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        history.push('/')
      }
    }
  }, [isAuthenticated, isLoading])

  useEffect(() => {
    if (hasError) {
      setFormLoading(false)
    }
  }, [hasError])

  return (
    <Form
      name="login"
      onFinish={onFinishPassword}
      fields={[
        {
          name: ['username'],
          value: username,
        },
        {
          name: ['token'],
          value: token,
        },
      ]}
      size="large"
    >
      <Form.Item
        name="password"
        rules={[
          // { required: true, message: 'Please input your password!' },
          { min: 8, message: 'Password must have a minimum length of 8' },
          {
            pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
            message:
              'Password must contain at least one lowercase letter, uppercase letter, number, and special character',
          },
        ]}
      >
        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
      </Form.Item>
      <Form.Item
        name="confirm"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            //  required: true,
            //  message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'))
            },
          }),
        ]}
      >
        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Confirm Password" />
      </Form.Item>
      <Form.Item hidden name="username">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item hidden name="token">
        <Input type="hidden" />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%', marginBottom: '0px', background: '#254874' }}
          loading={formLoading}
        >
          Update Password
        </Button>
        <Button
          type="link"
          style={{ fontSize: '12px', marginTop: '0px', color: '#254874', textAlign: 'center', width: '100%' }}
          onClick={() => returnToLogin()}
        >
          Back to Login
        </Button>
      </Form.Item>
    </Form>
  )
}

export default PasswordForm
